import React, { useState, useEffect } from "react"
// import HeaderNav from "../Nav/HeaderNav"
import Footer from "../Footer/Footer"
import PlayerSmall from "../Player/PlayerSmall"
import SearchModal from "../Modal/SearchModal"
import { GuestApiProvider } from "src/context/GuestApiContext"
import Auth from "../Auth/Auth"
import "./Container.scss"
import Meta from "./Meta"
import global from "../../global/global"
import { useModal } from "../../hooks/useModal"
import { useTracks } from "../../hooks/useTracks"
import { DidomiProvider } from "../Didomi/Didomi"
import { useLocation } from "@reach/router"
import Nav from "../Nav/Nav"

export default function Container({ children, page, variant = "" }) {
  const [cookiesAccepted, setCookiesAccepted] = useState(false);
  const [showSearch, setShowSearch] = useState(false)
  const [activeTab, setActiveTab] = useState("login")
  const toggleSearch = () => setShowSearch(!showSearch)
  global.modal = useModal()
  global.tracks = useTracks()
  const history = useLocation()

  useEffect(() => {
    if (history.search.includes("show=register")) {
      setActiveTab("register")
      global.modal.setShowAuthModal(!global.modal.loginModal)
    } else if (history.search.includes("show=login")) {
      setActiveTab("login")
      global.modal.setShowAuthModal(!global.modal.loginModal)
    }
  }, [history])

  const toggleLogin = () => {
    global.modal.setShowAuthModal(!global.modal.loginModal)
  }

  return (
    <GuestApiProvider>
      <DidomiProvider onCookiesAccepted={() => setCookiesAccepted(true)}>
        <div className={`nostalgie ${variant}`}>
          <Meta page={page} cookiesAccepted={cookiesAccepted} />
          <Nav
            variant={variant ?? ""}
            onPressSearch={toggleSearch}
            onPressLogin={toggleLogin}
            theme={page === "Home" ? "light" : "dark"}
          />
          <div className="page-content">{children}</div>
          <Footer variant={variant ?? ""} />
          <SearchModal visible={showSearch} handleClose={toggleSearch} />
          <Auth
            visible={global.modal.loginModal}
            close={() => global.modal.setShowAuthModal(!global.modal.loginModal)}
            activeTab={activeTab}
          />
        </div>
        <PlayerSmall />
      </DidomiProvider>
    </GuestApiProvider>
  )
}
